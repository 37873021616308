<template>
  <div class='tp-search' v-if="isShowSearch" @touchmove.prevent>
    <div class="body">
      <div class="pane">
        <div class="search-title"></div>
        <div class="search-input">
          <i></i>
          <input type="text" v-model='searchKey' :placeholder="searchTip">
        </div>
        <div class="search-btn">
          <button class='btn' @click='bindSearchChange'>搜索</button>
        </div>
      </div>
      <div class='close'>
        <van-icon name="close" @click='bindCloseChange'></van-icon>
      </div>
    </div>
  </div>
</template>

<script>

import store from "@/store/store";

export default {
  name: 'tpSearch',
  data() {
    return {
      state: store.state,
      isShowSearch: false,
      searchTip: '请输入选手编号或名称',
      searchStyle: '',
      searchKey: ''
    }
  },
  watch: {
    'state.searchStatus': function (newVal, oldVal) {
      this.isShowSearch = newVal
      if (newVal) {
        const {searchTip, searchColor,playerAppellation} = store.state.activity.settingShowElement;
        this.searchTip = searchTip ? searchTip : ('请输入' + playerAppellation + '编号或名称')
      }
    },
  },
  created() {
  },
  methods: {
    bindSearchChange() {
      let searchKey = this.searchKey;
      this.state.searchKey = searchKey
      store.state.searchStatus = false;
      if (this.$router.currentRoute.path != '/activity/vote') {
        this.$router.push({path: 'vote', query: {searchKey: searchKey}})
      }
      this.searchKey = ''

    },
    bindCloseChange() {
      store.state.searchStatus = false;
    }
  }
}
</script>
<style>
.tp-search {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 2000;
  top: 0;
  background-color: rgba(0, 0, 0, .7);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
