<template>
  <div class="group-bg tp-rank">
    <van-list v-model="load" @load="loadNextPage" :finished="finish"
              finished-text="没有更多了">
      <div class="item" v-for='(item,index) in ranks' :key='index' @click="bindPlayerChange(item.id)">
        <div class="top" v-if="index<3">
          <div class="top-base">
            <img v-if="config.hasPlayerCover" :src="item.imageUrl | ossAvatarFilter" alt="">
            <div class="rank">{{ index + 1|rankTopFilter }}</div>
          </div>
          <div class="top-info">
            <div class="num" v-if="config.hasPlayerNumber">{{ item.num }}号</div>
            <div class="blurb">
              <div class="name" v-if="config.hasPlayerName">{{ item.name }}</div>
              <div class="descr" v-if="config.hasPlayerSynopsis">{{ item.intro }}</div>
            </div>
            <div class="vote" v-if="config.hasPlayerVotes">{{ item.voteNum }}{{ voteUnit }}</div>
          </div>
        </div>
        <div class="end" v-else>
          <div class="end-rank">
            <span>{{ index + 1 }}</span>
          </div>
          <div class="end-avatar" v-if="config.hasPlayerCover">
            <img :src="item.imageUrl | ossAvatarFilter" alt="">
          </div>
          <div class="end-info">
            <div class="content">
              <div class="name"><span class="num" v-if="config.hasPlayerNumber">{{ item.num }}号</span>{{ item.name }}
              </div>
              <div class="vote" v-if="config.hasPlayerVotes">{{ item.voteNum }} {{ voteUnit }}</div>
            </div>
            <div class="descr" v-if="config.hasPlayerSynopsis">{{ item.intro }}</div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>

import store from "@/store/store";

export default {
  name: 'tpPlayer',
  data() {
    return {
      config: {},
      voteUnit: ''
    }
  },
  props: ['load', 'ranks', 'finish'],
  created() {
    let {settingShowElement, settingShowRanking} = store.state.activity
    this.config = settingShowRanking
    this.voteUnit = settingShowElement.votesUnit
  },
  methods: {
    loadNextPage() {
      this.$emit('onLoad');
    },

    bindPlayerChange(playerId) {
      this.$emit('onDetail', playerId);
    },
  }
}
</script>
