<template>
  <div class="tp-cover">
    <van-swipe @change="swipeEvent" class="carousel" :autoplay="3000" v-if="type===1">
      <van-swipe-item v-for="(item,index) in covers" :key="index" lazy-render>
        <div :class="{'item-auto':autoHeight,'active':swipeIndex===index}">
          <div v-if="item.coverType===2" class="video-code" v-html="item.content"></div>
          <img v-else :src="item.content|appendImgUrl" alt="" @click="bindCoverPreviewChange(item)">
        </div>
      </van-swipe-item>
    </van-swipe>
    <div class="nine" v-else-if="type===2">
      <div class="item" v-for='(item,index) in covers' :key='index'>
        <div v-if="item.coverType===2" class="video-code" v-html="item.content"></div>
        <img v-else :src="item.content|appendImgUrl" alt="" @click="bindCoverPreviewChange(item)">
      </div>
    </div>
    <div class="line" v-else>
      <div class="item" v-for='(item,index) in covers' :key='index'>
        <div v-if="item.coverType===2" class="video-code" v-html="item.content"></div>
        <img v-else :src="item.content|appendImgUrl" alt="" @click="bindCoverPreviewChange(item)">
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store/store";
import {config} from "@/js/config";
import {ImagePreview} from 'vant'

export default {
  name: 'YdCover',
  data() {
    return {
      settingShowPlayer: {},
      autoHeight: true,
      swipeIndex: 0
    }
  },
  props: ['type', 'covers', 'coverType', ''],
  created() {
    let {settingShowPlayer} = store.state.activity;
    this.settingShowPlayer = settingShowPlayer
  },
  methods: {
    swipeEvent(index) {
      this.swipeIndex = index
    },
    bindCoverPreviewChange(cover) {
      const hasCoverPreview = this.settingShowPlayer.hasCoverPreview;
      let {coverType, content} = cover;
      if (coverType === 1 && content && hasCoverPreview) {
        ImagePreview({
          images: [config.imgUrlHost + content],
          showIndex: false,
          loop: false,
        });
      }
    },
  }
}
</script>
<style lang="less" scoped>
.tp-cover {
  .carousel {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .van-swipe-item {
      height: auto;
    }

    .item-auto {
      display: block;
      opacity: 0;
      height: 0.1rem;
    }

    .item-auto.active {
      display: block;
      transition: all 1s ease-out;;
      opacity: 1;
      height: auto;
    }

    .video-code {
      width: 100%;
      height: 100%;

      ::v-deep iframe {
        display: block;
        width: 100%;
        height: 100%;
        min-height: 6rem;;
      }
    }

    img {
      display: block;
      width: 100%;
    }

    .van-notice-bar {

    }
  }

  .nine {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .item {
      width: calc(33.33% - 0.06rem);
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.09rem;
      margin-bottom: 0.09rem;

      .video-code {
        width: 100%;

        ::v-deep iframe {
          width: 100%;
          display: block;
        }
      }

      img {
        width: 100%;
        display: block;
        height: auto;
      }
    }

    .item:nth-child(3) {
      margin-right: 0;
    }
  }

  .line {
    .item {
      width: 100%;
      height: auto;
      margin: 0.2rem 0;

      .video-code {
        margin: 0.2rem 0;
        width: 100%;

        ::v-deep iframe {
          display: block;
          width: 100%;
        }
      }

      img {
        width: 100%;
        display: block;
        height: auto;
      }
    }

  }
}

</style>
