<template>
  <div class="tp-player">
    <template v-if="config.hasPlayerCover">
      <yd-cover v-if="coverList.length" :type="config.playerImagesShowType.index"
                :covers="coverList">
      </yd-cover>
      <!--占位-->
      <div v-else class="cover-def">
        <img src="@/assets/avatar-def.png" alt="">
      </div>
    </template>
    <div class="player-body">
      <div class="player-pane">
        <div class='player-count' v-if="config.hasPlayerData">
          <div class='count-item'>
            <div class="num">{{ player.ranking }}</div>
            <div class="title">
              排名
            </div>
          </div>
          <div class='count-item' v-if="config.hasPlayerVotes">
            <div class="num">{{ player.voteNum }}</div>
            <div class="title">
              {{ config.votesUnit }}数
            </div>
          </div>
          <div class='count-item' v-if="player.ranking > 3&&config.hasGap">
            <div class="num">{{ player.trailNum }} {{ config.votesUnit }}</div>
            <div class="title">
              距上一名
            </div>
          </div>
          <div class='count-item' v-else>
            <div class="num">{{ player.ranking|rankTopFilter }}</div>
            <div class="title">
              当前
            </div>
          </div>
        </div>
          <div class="player-num" v-if="config.hasPlayerNumber">
            <i>{{ player.num }}号</i>
          </div>
          <div class="player-name" :style="playerNameStyle" v-if="config.hasPlayerName">
            {{ player.name }}
          </div>
        <div class="player-blurb" :style="playerBlurbStyle" v-if="config.hasPlayerSynopsis">
          {{ player.intro }}
        </div>
        <!--选手详情-->
        <div class='player-content rich-text' v-if="player.content" v-html="player.content">
        </div>
        <!--按钮-->
        <div class='player-btn'>
          <button class='vote' @click='bindVoteChange' v-if="config.hasVotingButton">
            {{ isSel ? '已选择' : config.voteButtonName }}
          </button>
          <button class='help' @click='bindHelpChange' v-if="config.hasCanvassingButton">
            {{ config.canvassingButtonName ? config.canvassingButtonName : '为我拉票' }}
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import YdCover from "@/components/cover.vue";
import store from "@/store/store";
import {commonUtil} from "@/js/commonUtil";

export default {
  name: "tpPlayer",
  components: {YdCover},
  data() {
    return {
      playerNameStyle: {
        'text-align': 'center',
        '-webkit-line-clamp': 1,
      },
      playerBlurbStyle: {
        'text-align': 'center',
        '-webkit-line-clamp': 5,
      },
      coverList: []
    }
  },
  props: ['player', 'config', 'type', 'isSel'],
  created() {
    store.state.isShowBanner = false;
    this.initCover()
  },
  methods: {
    initCover() {
      let {
        infoNameRow,
        infoNameAlign,
        infoBlurbRow,
        infoBlurbAlign, hasPlayerCover
      } = this.config;
      if (infoNameRow) {
        this.playerNameStyle['-webkit-line-clamp'] = infoNameRow
      }
      if (infoNameAlign) {
        this.playerNameStyle['text-align'] = infoNameAlign
      }
      if (infoBlurbRow) {
        this.playerBlurbStyle['-webkit-line-clamp'] = infoBlurbRow
      }
      if (infoBlurbAlign) {
        this.playerBlurbStyle['text-align'] = infoBlurbAlign
      }
      let {imageUrl, imageUrls, content, coverType, videoCode} = this.player;
      content = commonUtil.richTextFilter(content)
      if (hasPlayerCover && (imageUrl || videoCode)) {//是否有封面
        this.coverList.push({coverType: coverType, content: coverType === 2 ? videoCode : imageUrl});
      }
      if (imageUrls && imageUrls.length) {
        for (let i in imageUrls) {
          this.coverList.push({coverType: 1, content: imageUrls[i]});
        }
      }
    },
    bindHelpChange() {
      this.$emit('onHelp');
    },
    bindVoteChange() {
      this.$emit('onVote');
    },
  }
}
</script>

<style>

</style>
